import { useState, useRef } from "react";
import Overlay from "react-bootstrap/Overlay";
import Tooltip from "react-bootstrap/Tooltip";
import { Helmet } from "react-helmet";
import Navigation from "../components/Navigation";
import { Link as ScrollLink } from "react-scroll";
// import { Link as RouterLink } from "react-router-dom";
import NewsLetter from "../components/NewsLetter";

const Coupons = () => {
  const [show, setShow] = useState(false);
  const target = useRef(null);
  const text = "WELCOMETRX";
  // const holi = "On wallet recharge of Rs. 5,000 or more & get upto 20% cashback ";

  const newsletterProps = {
    background: `url("/image/coupons/newsletter.png") center center / 100% 100% no-repeat`,
    content: "Stay informed about Coupons and Discounts",

    // backgroundImage,
  };
  const faq = {
    background: `url("/image/faq/bg_faq.png")`,
    width: "100%",
    backgroundSize: "100% 100%",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    // height: "85vh",
    padding: "7% 0%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  };
  const coupons = {
    background: `url("/image/coupons/frame-coupons.png")`,
    width: "100%",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    height: "100vh",
    padding: "4rem",
    borderRadius: "20px",
 
  };
  return (
    <>
      <Helmet>
        <title>Coupons</title>
        <meta
          name="description"
          content="Explore our Frequently Asked Questions to gain insights into Truxcargo’s services. Find answers to commonly raised queries about our logistics solutions."
        />
        <meta name="keywords" content="Indian courier services" />
      </Helmet>
      <Navigation />

      <section className="contact_section" style={faq}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 step-part">
              <div className="left-side primum-steps">
                <h4 className="mb-4">Grab your coupon </h4>

                <ScrollLink to="coupons">
                  <button class="btn btn-outline-light ">Explore</button>
                </ScrollLink>
              </div>
            </div>
            <div className="col-md-6">
              <div className="right_side">
                <img
                  src="/image/coupons/1.png"
                  alt=""
                  srcSet=""
                  className="w-100"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <div id="coupons" className="container-fluid">
        <section
          className="coupons_available margin_top_bottom"
          style={coupons}
        >
          <div className="container">
            <h3>
              {" "}
              <u className="mt-5">Available Coupons </u>{" "}
            </h3>
            <div className="row mt-5">
            {/* <div className="col-md-6">
                <div className="main_wrapper">
                  <div className="layout_left">
                    <div className="coupons_card">
                      <div className="coupon_image">
                        <img
                          src="/image/coupons/holi.png"
                          alt=""
                          srcSet=""
                          className="w-100"
                        />
                      </div>
                      <div className="coupon_content">
                        <div>
                          <div>
                            <p style={{ borderBottom: "2px solid" }}>Holi Special Offer</p>
                          </div>
                          
                        </div>
                        <h6 style={{ color: "#7939CC" }}>
                          {" "}
                          <b>{holi}</b>{" "}
                        </h6>
                        <div style={{ marginTop: "2rem" }}>
                          <small>Terms and conditions </small>
                          <ul>
                           
                           
                            <li>
                            Offer is valid from 20th March to 10th April.</li>
                            <li>
                            Offer can be available maximum of twice per user.
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="layout_right">
                    <p>HOLI&nbsp;OFFER </p>
                  </div>
                </div>
              </div> */}
              <div className="col-md-6">
                <div className="main_wrapper">
                  <div className="layout_left">
                    <div className="coupons_card">
                      <div className="coupon_image">
                        <img
                          src="/image/coupons/sparkles.png"
                          alt=""
                          srcSet=""
                          className="w-100"
                        />
                      </div>
                      <div className="coupon_content">
                        <div className="d-flex justify-content-between">
                          <div>
                            <p style={{ borderBottom: "2px solid" }}>Code</p>
                          </div>
                          <div>
                            <img
                              src="/image/coupons/copy.svg"
                              className="w-100"
                              alt=""
                              srcSet=""
                              role="button"
                              ref={target}
                              onClick={() => {
                                setShow(!show);
                                navigator.clipboard.writeText(text);
                              }}
                            />

                            <Overlay
                              target={target.current}
                              show={show}
                              placement="right"
                            >
                              {(props) => (
                                <Tooltip id="overlay-example" {...props}>
                                  Copied
                                </Tooltip>
                              )}
                            </Overlay>
                          </div>
                        </div>
                        <h6 style={{ color: "#7939CC" }}>
                          {" "}
                          <b>{text}</b>{" "}
                        </h6>
                        <div style={{ marginTop: "4rem" }}>
                          <small>Terms and conditions </small>
                          <ul>
                            <li>
                              Upon the amount of your first order, enjoy FLAT
                              Rs. 100 OFF
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="layout_right">
                    <p>WELCOME&nbsp;COUPON </p>
                  </div>
                </div>
              </div>

             
            </div>
          </div>
        </section>
      </div>

      <NewsLetter {...newsletterProps} />
    </>
  );
};

export default Coupons;
