import React, { useEffect } from "react";
import footerlogo from "../assets/images/footer-logo.svg";
import Accordion from "react-bootstrap/Accordion";
import playstore from "../assets/images/playstore.png";
import monument from "../assets/images/monument.png";
import applestore from "../assets/images/applestore.png";
// import manimg from "../assets/images/partner/man.png";
import { Link } from "react-router-dom";
import ScrollToTopButton from "./ScrollToTopButton";
import Aos from "aos";
import { HashLink } from 'react-router-hash-link';


const Footer = () => {

  
  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <>
      <section className="footer-section  margin_top_bottom">
        <div className="monuments_footer">
          <h2 data-aos="fade-up" data-aos-duration="1000">
            Delivering Items in all over India
          </h2>
          <img src={monument} alt="error" srcSet="" className="w-100" />
        </div>
        <div className="container-fluid mt-5">
          <div className="row">
            <div className="col-lg-3 col-md-12 col-9">
              <div>
                <h3>
                  {" "}
                  <Link to="/">
                    {" "}
                    <img
                      src={footerlogo}
                      alt=""
                      srcSet=""
                      className="footer-logo"
                    />
                  </Link>
                </h3>
              </div>
              <ul>
                <p className="footer_about">
                  Welcome to TRUXCARGO! We are a pioneering logistics technology
                  company with a mission to transform the way goods are moved,
                  managed, and delivered. Our AI-enabled platform is designed to
                  optimize supply chain operations, streamline transportation
                  processes, and enhance overall efficiency.
                </p>
              </ul>

              <div className="d-flex gap-2 my-4">
                <Link to="https://play.google.com/store/apps/details?id=com.Truxcargo&hl=en" target="_blank" rel="noopener noreferrer">
                  {" "}
                  <img src={playstore} alt="" srcSet="" className="w-100"  />
                </Link>
                <Link to="https://apps.apple.com/in/app/truxcargo-courier-track/id6505090308" target="_blank" rel="noopener noreferrer">
                  {" "}
                  <img src={applestore} alt="" srcSet="" className="w-100" />
                </Link>
              </div>
            </div>
            <div className=" footer_about col-lg-2 col-md-3 col-6 ">
              <h3>Services</h3>
              <ul>
                <HashLink to="/service#Ecommerce">
                  <li>Ecommerce Shipping</li>
                </HashLink>
                <HashLink to="/service#Document">
                  <li>Secure Docs Delivery </li>
                </HashLink>
                <HashLink to="/service#B2C">
                  <li>Consumer Air Cargo </li>
                </HashLink>
                <HashLink to="/service#Full_Truck">
                  <li>Full Load Transport </li>
                </HashLink>
                <HashLink to="/service#Part_Truck">
                  <li>Partial Load Freight</li>
                </HashLink>
                <HashLink to="/service#Hire_fleet">
                  <li>Fleet Rental Solutions</li>
                </HashLink>
              </ul>
            </div>
            <div className="footer_about col-lg-2 col-md-3 col-6  ">
              <h3>Informations</h3>
              <ul>
                <Link to="/about">
                  <li>About us</li>
                </Link>
                <Link to="/faq">
                  <li>FAQ</li>
                </Link>
                <Link to="/Career">
                  <li>Career</li>
                </Link>
                <Link to="/blog">
                  {" "}
                  <li>Blogs</li>
                </Link>{" "}
                <Link to="/privacypolicy">
                  <li>Privacy Policy </li>
                </Link>
                <Link to="/termandcondition">
                  <li>Terms & Conditions</li>
                </Link>
                <Link to="/refundandcancellation">
                  <li> Refund & Cancellation Policy</li>
                </Link>
                <Link to="/truxcargo-application">
                  <li> Truxcargo Application</li>
                </Link>
              </ul>
            </div>
            <div className="footer_about col-lg-2 col-md-3 col-12 ">
              <h3>Solutions</h3>
              <ul>
                <Link to="/ndr_managment">
                  <li>NDR Management</li>
                </Link>
                <Link to="/weightReconciliation">
                  {" "}
                  <li>Weight Reconciliation</li>
                </Link>
                <Link to="/cod_remittance">
                  <li>Timely COD Remittance</li>
                </Link>
                <Link to="/multiple_pickup">
                  <li>Multiple Pickup Location</li>
                </Link>
                {/* <Link to="/multiple_pickup"><li>Multiple PickupLocation</li></Link> */}
                <Link to="/Multiple_ShippingPartner">
                  <li>Multiple Shipping Partner</li>
                </Link>
                <Link to="/ApiIntegration">
                  <li>API Integration</li>
                </Link>
                <Link to="/ShippingRateCalculator">
                  <li>Shipping Rate Calculator</li>
                </Link>
              </ul>
            </div>

            <div className="col-lg-3 col-md-3">
              <div>
                <h3>Get In Touch</h3>
              </div>
              <ul>
                <div className="d-flex">
                  <i className="fa-solid fa-location-dot"></i>
                  <p>
                    801-802, 8th Floor, KLJ Tower, Plot No. B-5, NSP, Pitampura,
                    Delhi - 110034
                  </p>
                </div>

                <div className="d-flex">
                  <i className="fa-solid fa-envelope"></i>
                  <p>support@truxcargo.com</p>
                </div>
                <div className="d-flex">
                  <i className="fa-solid fa-phone"></i>
                  <p>+91-9315033113</p>
                </div>
              </ul>
            </div>

            <div className="col-sm-12">
              <div className="footer_accordion">
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header> Services </Accordion.Header>
                    <Accordion.Body>
                      <ul>
                        <Link to="/service">
                          <li>Ecommerce Shipping</li>
                        </Link>
                        <Link to="/service">
                          <li>Secure Docs Delivery </li>
                        </Link>
                        <Link to="/service">
                          <li>Consumer Air Cargo </li>
                        </Link>
                        <Link to="/service">
                          <li>Full Load Transport </li>
                        </Link>
                        <Link to="/service">
                          <li>Partial Load Freight</li>
                        </Link>
                        <Link to="/service">
                          <li>Fleet Rental Solutions</li>
                        </Link>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>Informations</Accordion.Header>
                    <Accordion.Body>
                      <ul>
                        <Link to="/about">
                          <li>About us</li>
                        </Link>
                        <Link to="/faq">
                          <li>FAQ</li>
                        </Link>
                        <Link to="/Career">
                          <li>Career</li>
                        </Link>
                        <Link to="/blog">
                          {" "}
                          <li>Blogs</li>
                        </Link>{" "}
                        <Link to="/privacypolicy">
                          <li>Privacy Policy </li>
                        </Link>
                        <Link to="/termandcondition">
                          <li>Terms & Conditions</li>
                        </Link>
                        <Link to="/refundandcancellation">
                          <li> Refund & Cancellation Policy</li>
                        </Link>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>Solutions</Accordion.Header>
                    <Accordion.Body>
                      <ul>
                        <Link to="/ndr_managment">
                          <li>NDR Management</li>
                        </Link>
                        <Link to="/weightReconciliation">
                          {" "}
                          <li>Weight Reconciliation</li>
                        </Link>
                        <Link to="/cod_remittance">
                          <li>Timely COD Remittance</li>
                        </Link>
                        <Link to="/multiple_pickup">
                          <li>Multiple Pickup Location</li>
                        </Link>
                        {/* <Link to="/multiple_pickup"><li>Multiple PickupLocation</li></Link> */}
                        <Link to="/Multiple_ShippingPartner">
                          <li>Multiple Shipping Partner</li>
                        </Link>
                        <Link to="/ApiIntegration">
                          <li>API Integration</li>
                        </Link>
                        <Link to="/ShippingRateCalculator">
                          <li>Shipping Rate Calculator</li>
                        </Link>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>

          <div className="row justify-content-end ">
            {/* <div className="col-lg-4"></div> */}
            <div className="col-lg-4 col-md-8">
              <div className=" button-85 social-media ">
                <h5 className="mb-3 text-white"  >Follow us</h5>
                <a
                  href="https://www.facebook.com/truxcargo.in/"
                  target="_blank" rel="noreferrer"
                >
                  <img src="/image/social_media/twiter.svg" />
                </a>
                <a href="https://www.instagram.com/truxcargo/" target="_blank" rel="noreferrer">
                  <img src="/image/social_media/facebook.svg" />
                </a>
                <a href="https://www.twitter.com/truxcargo1" target="_blank" rel="noreferrer">
                  <img src="/image/social_media/instagram.svg" />
                </a>

                <a
                  href=" https://www.linkedin.com/company/truxcargo"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="/image/social_media/linkdin.svg" />
                </a>
              </div>
            </div>

            <div className="col-lg-4 col-md-4">
              <div className="footer_accordion text-center">
                <p>
                  Made In{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="60"
                    height="38"
                    viewBox="0 0 60 38"
                    fill="none"
                    className="flag"
                  >
                    <g clipPath="url(#clip0_1585_8014)">
                      <path
                        d="M0.492188 4C0.492188 1.79086 2.28305 0 4.49219 0H55.5163C57.7254 0 59.5163 1.79086 59.5163 4V12.6667H0.492188V4Z"
                        fill="#FF9933"
                      />
                      <path
                        d="M0.492188 12.667H59.5163V25.3337H0.492188V12.667Z"
                        fill="white"
                      />
                      <path
                        d="M0.492188 25.333H59.5163V33.9997C59.5163 36.2088 57.7254 37.9997 55.5163 37.9997H4.49219C2.28305 37.9997 0.492188 36.2088 0.492188 33.9997V25.333Z"
                        fill="#128807"
                      />
                      <path
                        d="M29.4244 23.5923C32.2217 23.5923 34.4894 21.5012 34.4894 18.9216C34.4894 16.3421 32.2217 14.251 29.4244 14.251C26.6271 14.251 24.3594 16.3421 24.3594 18.9216C24.3594 21.5012 26.6271 23.5923 29.4244 23.5923Z"
                        fill="#000088"
                      />
                      <path
                        d="M29.4241 23.0086C31.8717 23.0086 33.8559 21.1789 33.8559 18.9218C33.8559 16.6647 31.8717 14.835 29.4241 14.835C26.9764 14.835 24.9922 16.6647 24.9922 18.9218C24.9922 21.1789 26.9764 23.0086 29.4241 23.0086Z"
                        fill="white"
                      />
                      <path
                        d="M29.4255 19.7392C29.915 19.7392 30.3119 19.3733 30.3119 18.9219C30.3119 18.4704 29.915 18.1045 29.4255 18.1045C28.936 18.1045 28.5391 18.4704 28.5391 18.9219C28.5391 19.3733 28.936 19.7392 29.4255 19.7392Z"
                        fill="#000088"
                      />
                      <path
                        d="M33.7922 19.67C33.9171 19.6852 34.0317 19.6042 34.0481 19.4891C34.0646 19.374 33.9766 19.2684 33.8517 19.2533C33.7267 19.2381 33.6121 19.3191 33.5957 19.4342C33.5792 19.5493 33.6672 19.6549 33.7922 19.67Z"
                        fill="#000088"
                      />
                      <path
                        d="M29.4255 23.0133L29.5776 20.5612L29.4255 19.3936L29.2734 20.5612L29.4255 23.0133Z"
                        fill="#000088"
                      />
                      <path
                        d="M33.43 20.6868C33.5464 20.7312 33.6798 20.6803 33.728 20.573C33.7762 20.4658 33.7209 20.3428 33.6045 20.2983C33.4881 20.2538 33.3546 20.3047 33.3065 20.412C33.2583 20.5193 33.3136 20.6423 33.43 20.6868Z"
                        fill="#000088"
                      />
                      <path
                        d="M28.2812 22.8748L29.1164 20.5422L29.2972 19.3779L28.8226 20.4696L28.2812 22.8748Z"
                        fill="#000088"
                      />
                      <path
                        d="M32.8002 21.5817C32.9002 21.6525 33.0433 21.6351 33.12 21.543C33.1967 21.4508 33.1778 21.3188 33.0779 21.2481C32.9779 21.1773 32.8347 21.1947 32.7581 21.2868C32.6814 21.379 32.7003 21.511 32.8002 21.5817Z"
                        fill="#000088"
                      />
                      <path
                        d="M27.2109 22.4644L28.6721 20.4109L29.1735 19.3296L28.4088 20.2708L27.2109 22.4644Z"
                        fill="#000088"
                      />
                      <path
                        d="M31.9456 22.2959C32.0223 22.388 32.1655 22.4054 32.2654 22.3347C32.3653 22.264 32.3842 22.132 32.3075 22.0398C32.2308 21.9477 32.0876 21.9303 31.9877 22.001C31.8878 22.0717 31.8689 22.2037 31.9456 22.2959Z"
                        fill="#000088"
                      />
                      <path
                        d="M26.2969 21.8149L28.2846 20.1801L29.0725 19.2554L28.0697 19.9819L26.2969 21.8149Z"
                        fill="#000088"
                      />
                      <path
                        d="M30.9081 22.7801C30.9562 22.8874 31.0897 22.9383 31.206 22.8939C31.3224 22.8494 31.3777 22.7264 31.3295 22.6191C31.2813 22.5118 31.1479 22.4608 31.0315 22.5053C30.9151 22.5498 30.8599 22.6728 30.9081 22.7801Z"
                        fill="#000088"
                      />
                      <path
                        d="M25.5859 20.9672L27.9648 19.8624L28.9855 19.1572L27.8129 19.6198L25.5859 20.9672Z"
                        fill="#000088"
                      />
                      <path
                        d="M29.7754 23.0049C29.7918 23.12 29.9064 23.2011 30.0313 23.1859C30.1561 23.1707 30.2441 23.0651 30.2276 22.9499C30.2112 22.8348 30.0966 22.7537 29.9718 22.7689C29.8469 22.7841 29.759 22.8897 29.7754 23.0049Z"
                        fill="#000088"
                      />
                      <path
                        d="M25.1406 19.9834L27.7484 19.484L28.9322 19.0464L27.6698 19.2134L25.1406 19.9834Z"
                        fill="#000088"
                      />
                      <path
                        d="M28.6114 22.9485C28.5949 23.0637 28.6828 23.1693 28.8077 23.1844C28.9327 23.1996 29.0472 23.1185 29.0637 23.0034C29.0801 22.8883 28.9922 22.7826 28.8673 22.7675C28.7424 22.7523 28.6278 22.8334 28.6114 22.9485Z"
                        fill="#000088"
                      />
                      <path
                        d="M24.9922 18.9234L27.6513 19.063L28.9175 18.923L27.6513 18.7832L24.9922 18.9234Z"
                        fill="#000088"
                      />
                      <path
                        d="M27.5174 22.6182C27.4692 22.7255 27.5245 22.8485 27.6409 22.893C27.7572 22.9374 27.8907 22.8865 27.9389 22.7792C27.9871 22.6719 27.9318 22.5488 27.8154 22.5044C27.699 22.4599 27.5656 22.5109 27.5174 22.6182Z"
                        fill="#000088"
                      />
                      <path
                        d="M25.1406 17.8657L27.6698 18.6356L28.9322 18.8025L27.7484 18.3649L25.1406 17.8657Z"
                        fill="#000088"
                      />
                      <path
                        d="M26.5393 22.0368C26.4626 22.1289 26.4815 22.2611 26.5814 22.3318C26.6814 22.4025 26.8245 22.3851 26.9012 22.293C26.9779 22.2009 26.959 22.0688 26.859 21.9981C26.7591 21.9274 26.6159 21.9447 26.5393 22.0368Z"
                        fill="#000088"
                      />
                      <path
                        d="M25.5781 16.8809L27.805 18.2283L28.9776 18.6908L27.9569 17.9857L25.5781 16.8809Z"
                        fill="#000088"
                      />
                      <path
                        d="M25.7611 21.2427C25.6611 21.3134 25.6423 21.4454 25.719 21.5376C25.7957 21.6298 25.9389 21.6472 26.0388 21.5765C26.1387 21.5058 26.1575 21.3737 26.0808 21.2816C26.0041 21.1894 25.861 21.172 25.7611 21.2427Z"
                        fill="#000088"
                      />
                      <path
                        d="M26.2812 16.0327L28.0542 17.8657L29.057 18.5923L28.269 17.6676L26.2812 16.0327Z"
                        fill="#000088"
                      />
                      <path
                        d="M25.2424 20.2924C25.1261 20.3369 25.0708 20.4599 25.119 20.5672C25.1672 20.6745 25.3007 20.7255 25.417 20.6811C25.5334 20.6366 25.5886 20.5136 25.5404 20.4063C25.4922 20.2989 25.3588 20.248 25.2424 20.2924Z"
                        fill="#000088"
                      />
                      <path
                        d="M27.2109 15.3853L28.4089 17.5789L29.1736 18.5201L28.6721 17.4389L27.2109 15.3853Z"
                        fill="#000088"
                      />
                      <path
                        d="M25.003 19.2469C24.8781 19.2621 24.7902 19.3677 24.8067 19.4829C24.8231 19.598 24.9377 19.6791 25.0626 19.664C25.1875 19.6488 25.2754 19.5432 25.2589 19.428C25.2425 19.3128 25.1279 19.2318 25.003 19.2469Z"
                        fill="#000088"
                      />
                      <path
                        d="M28.2812 14.9761L28.8228 17.3809L29.2973 18.4726L29.1164 17.3084L28.2812 14.9761Z"
                        fill="#000088"
                      />
                      <path
                        d="M25.0626 18.1737C24.9377 18.1585 24.8231 18.2396 24.8067 18.3547C24.7903 18.4699 24.8782 18.5755 25.0031 18.5907C25.128 18.6059 25.2426 18.5248 25.259 18.4097C25.2754 18.2945 25.1875 18.1889 25.0626 18.1737Z"
                        fill="#000088"
                      />
                      <path
                        d="M29.4248 14.8306L29.2734 17.2827L29.4253 18.4504L29.5768 17.2827L29.4248 14.8306Z"
                        fill="#000088"
                      />
                      <path
                        d="M25.417 17.1645C25.3006 17.1201 25.1672 17.171 25.119 17.2783C25.0708 17.3856 25.1261 17.5086 25.2425 17.5531C25.3589 17.5975 25.4923 17.5466 25.5405 17.4393C25.5886 17.332 25.5334 17.209 25.417 17.1645Z"
                        fill="#000088"
                      />
                      <path
                        d="M30.5706 14.9771L29.7356 17.3092L29.5547 18.4734L30.0292 17.3819L30.5706 14.9771Z"
                        fill="#000088"
                      />
                      <path
                        d="M26.0466 16.2676C25.9467 16.1969 25.8035 16.2142 25.7268 16.3064C25.6502 16.3985 25.669 16.5305 25.769 16.6012C25.8689 16.672 26.0121 16.6546 26.0888 16.5625C26.1654 16.4703 26.1466 16.3383 26.0466 16.2676Z"
                        fill="#000088"
                      />
                      <path
                        d="M31.6423 15.3848L30.1812 17.4383L29.6797 18.5196L30.4444 17.5784L31.6423 15.3848Z"
                        fill="#000088"
                      />
                      <path
                        d="M26.909 15.5516C26.8323 15.4594 26.6891 15.442 26.5892 15.5127C26.4893 15.5834 26.4705 15.7155 26.5472 15.8076C26.6239 15.8998 26.767 15.9172 26.8669 15.8465C26.9669 15.7758 26.9857 15.6438 26.909 15.5516Z"
                        fill="#000088"
                      />
                      <path
                        d="M32.5569 16.0337L30.5692 17.6684L29.7812 18.5932L30.7842 17.8666L32.5569 16.0337Z"
                        fill="#000088"
                      />
                      <path
                        d="M27.9388 15.0674C27.8906 14.96 27.7572 14.9091 27.6408 14.9536C27.5245 14.998 27.4692 15.1211 27.5174 15.2284C27.5656 15.3357 27.699 15.3866 27.8154 15.3421C27.9317 15.2977 27.987 15.1747 27.9388 15.0674Z"
                        fill="#000088"
                      />
                      <path
                        d="M33.2589 16.8804L30.8801 17.9851L29.8594 18.6903L31.032 18.2278L33.2589 16.8804Z"
                        fill="#000088"
                      />
                      <path
                        d="M29.0715 14.8431C29.0551 14.7279 28.9405 14.6469 28.8156 14.662C28.6907 14.6772 28.6028 14.7829 28.6192 14.898C28.6357 15.0132 28.7502 15.0942 28.8751 15.0791C29 15.0639 29.0879 14.9582 29.0715 14.8431Z"
                        fill="#000088"
                      />
                      <path
                        d="M33.7058 17.8643L31.0979 18.3637L29.9141 18.8012L31.1766 18.6343L33.7058 17.8643Z"
                        fill="#000088"
                      />
                      <path
                        d="M30.2355 14.895C30.252 14.7799 30.164 14.6742 30.0391 14.6591C29.9142 14.6439 29.7996 14.725 29.7832 14.8401C29.7667 14.9553 29.8547 15.0609 29.9796 15.0761C30.1045 15.0912 30.2191 15.0102 30.2355 14.895Z"
                        fill="#000088"
                      />
                      <path
                        d="M33.855 18.9204L31.1959 18.7808L29.9297 18.9209L31.1959 19.0606L33.855 18.9204Z"
                        fill="#000088"
                      />
                      <path
                        d="M31.3294 15.2284C31.3776 15.121 31.3224 14.998 31.206 14.9536C31.0896 14.9091 30.9562 14.9601 30.908 15.0674C30.8598 15.1747 30.9151 15.2977 31.0314 15.3422C31.1478 15.3866 31.2812 15.3357 31.3294 15.2284Z"
                        fill="#000088"
                      />
                      <path
                        d="M33.7057 19.9803L31.1765 19.2103L29.9141 19.0435L31.0978 19.4811L33.7057 19.9803Z"
                        fill="#000088"
                      />
                      <path
                        d="M32.3074 15.812C32.3841 15.7199 32.3653 15.5878 32.2653 15.5171C32.1654 15.4464 32.0222 15.4638 31.9455 15.556C31.8689 15.6481 31.8877 15.7802 31.9876 15.8509C32.0875 15.9216 32.2307 15.9042 32.3074 15.812Z"
                        fill="#000088"
                      />
                      <path
                        d="M33.2588 20.9676L31.032 19.6202L29.8594 19.1577L30.88 19.8629L33.2588 20.9676Z"
                        fill="#000088"
                      />
                      <path
                        d="M33.0779 16.6042C33.1778 16.5335 33.1967 16.4014 33.12 16.3093C33.0433 16.2171 32.9002 16.1998 32.8002 16.2705C32.7003 16.3412 32.6814 16.4732 32.7581 16.5654C32.8348 16.6575 32.9779 16.6749 33.0779 16.6042Z"
                        fill="#000088"
                      />
                      <path
                        d="M32.5569 21.814L30.7842 19.981L29.7812 19.2544L30.5692 20.1792L32.5569 21.814Z"
                        fill="#000088"
                      />
                      <path
                        d="M33.6045 17.5561C33.7209 17.5116 33.7762 17.3886 33.728 17.2813C33.6798 17.1739 33.5463 17.123 33.43 17.1675C33.3136 17.2119 33.2583 17.3349 33.3065 17.4422C33.3547 17.5496 33.4881 17.6005 33.6045 17.5561Z"
                        fill="#000088"
                      />
                      <path
                        d="M31.6345 22.4634L30.4366 20.2697L29.6719 19.3286L30.1734 20.4099L31.6345 22.4634Z"
                        fill="#000088"
                      />
                      <path
                        d="M33.8438 18.6005C33.9687 18.5853 34.0566 18.4797 34.0402 18.3645C34.0237 18.2494 33.9092 18.1683 33.7843 18.1835C33.6594 18.1986 33.5715 18.3043 33.5879 18.4195C33.6044 18.5346 33.7189 18.6157 33.8438 18.6005Z"
                        fill="#000088"
                      />
                      <path
                        d="M30.5707 22.8714L30.0293 20.4666L29.5547 19.375L29.7357 20.5391L30.5707 22.8714Z"
                        fill="#000088"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1585_8014">
                        <rect width="60" height="38" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>{" "}
                  with <i className="fa-solid fa-heart"></i>{" "}
                </p>
              </div>
              <ScrollToTopButton />
            </div>
          </div>
          <div className="row justify-content-end"></div>
          <hr />
          <div className="row justify-content-center">
            <div className="col-md-8">
              <div className="footer_term">
                <p>Copyright © 2024 TRUXCARGO PRIVATE LIMITED. All rights reserved.</p>
                <div className="footer_about">
                  <p>
                    Made In &nbsp;
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="60"
                      height="38"
                      viewBox="0 0 60 38"
                      fill="none"
                      className="flag"
                    >
                      <g clipPath="url(#clip0_1585_8014)">
                        <path
                          d="M0.492188 4C0.492188 1.79086 2.28305 0 4.49219 0H55.5163C57.7254 0 59.5163 1.79086 59.5163 4V12.6667H0.492188V4Z"
                          fill="#FF9933"
                        />
                        <path
                          d="M0.492188 12.667H59.5163V25.3337H0.492188V12.667Z"
                          fill="white"
                        />
                        <path
                          d="M0.492188 25.333H59.5163V33.9997C59.5163 36.2088 57.7254 37.9997 55.5163 37.9997H4.49219C2.28305 37.9997 0.492188 36.2088 0.492188 33.9997V25.333Z"
                          fill="#128807"
                        />
                        <path
                          d="M29.4244 23.5923C32.2217 23.5923 34.4894 21.5012 34.4894 18.9216C34.4894 16.3421 32.2217 14.251 29.4244 14.251C26.6271 14.251 24.3594 16.3421 24.3594 18.9216C24.3594 21.5012 26.6271 23.5923 29.4244 23.5923Z"
                          fill="#000088"
                        />
                        <path
                          d="M29.4241 23.0086C31.8717 23.0086 33.8559 21.1789 33.8559 18.9218C33.8559 16.6647 31.8717 14.835 29.4241 14.835C26.9764 14.835 24.9922 16.6647 24.9922 18.9218C24.9922 21.1789 26.9764 23.0086 29.4241 23.0086Z"
                          fill="white"
                        />
                        <path
                          d="M29.4255 19.7392C29.915 19.7392 30.3119 19.3733 30.3119 18.9219C30.3119 18.4704 29.915 18.1045 29.4255 18.1045C28.936 18.1045 28.5391 18.4704 28.5391 18.9219C28.5391 19.3733 28.936 19.7392 29.4255 19.7392Z"
                          fill="#000088"
                        />
                        <path
                          d="M33.7922 19.67C33.9171 19.6852 34.0317 19.6042 34.0481 19.4891C34.0646 19.374 33.9766 19.2684 33.8517 19.2533C33.7267 19.2381 33.6121 19.3191 33.5957 19.4342C33.5792 19.5493 33.6672 19.6549 33.7922 19.67Z"
                          fill="#000088"
                        />
                        <path
                          d="M29.4255 23.0133L29.5776 20.5612L29.4255 19.3936L29.2734 20.5612L29.4255 23.0133Z"
                          fill="#000088"
                        />
                        <path
                          d="M33.43 20.6868C33.5464 20.7312 33.6798 20.6803 33.728 20.573C33.7762 20.4658 33.7209 20.3428 33.6045 20.2983C33.4881 20.2538 33.3546 20.3047 33.3065 20.412C33.2583 20.5193 33.3136 20.6423 33.43 20.6868Z"
                          fill="#000088"
                        />
                        <path
                          d="M28.2812 22.8748L29.1164 20.5422L29.2972 19.3779L28.8226 20.4696L28.2812 22.8748Z"
                          fill="#000088"
                        />
                        <path
                          d="M32.8002 21.5817C32.9002 21.6525 33.0433 21.6351 33.12 21.543C33.1967 21.4508 33.1778 21.3188 33.0779 21.2481C32.9779 21.1773 32.8347 21.1947 32.7581 21.2868C32.6814 21.379 32.7003 21.511 32.8002 21.5817Z"
                          fill="#000088"
                        />
                        <path
                          d="M27.2109 22.4644L28.6721 20.4109L29.1735 19.3296L28.4088 20.2708L27.2109 22.4644Z"
                          fill="#000088"
                        />
                        <path
                          d="M31.9456 22.2959C32.0223 22.388 32.1655 22.4054 32.2654 22.3347C32.3653 22.264 32.3842 22.132 32.3075 22.0398C32.2308 21.9477 32.0876 21.9303 31.9877 22.001C31.8878 22.0717 31.8689 22.2037 31.9456 22.2959Z"
                          fill="#000088"
                        />
                        <path
                          d="M26.2969 21.8149L28.2846 20.1801L29.0725 19.2554L28.0697 19.9819L26.2969 21.8149Z"
                          fill="#000088"
                        />
                        <path
                          d="M30.9081 22.7801C30.9562 22.8874 31.0897 22.9383 31.206 22.8939C31.3224 22.8494 31.3777 22.7264 31.3295 22.6191C31.2813 22.5118 31.1479 22.4608 31.0315 22.5053C30.9151 22.5498 30.8599 22.6728 30.9081 22.7801Z"
                          fill="#000088"
                        />
                        <path
                          d="M25.5859 20.9672L27.9648 19.8624L28.9855 19.1572L27.8129 19.6198L25.5859 20.9672Z"
                          fill="#000088"
                        />
                        <path
                          d="M29.7754 23.0049C29.7918 23.12 29.9064 23.2011 30.0313 23.1859C30.1561 23.1707 30.2441 23.0651 30.2276 22.9499C30.2112 22.8348 30.0966 22.7537 29.9718 22.7689C29.8469 22.7841 29.759 22.8897 29.7754 23.0049Z"
                          fill="#000088"
                        />
                        <path
                          d="M25.1406 19.9834L27.7484 19.484L28.9322 19.0464L27.6698 19.2134L25.1406 19.9834Z"
                          fill="#000088"
                        />
                        <path
                          d="M28.6114 22.9485C28.5949 23.0637 28.6828 23.1693 28.8077 23.1844C28.9327 23.1996 29.0472 23.1185 29.0637 23.0034C29.0801 22.8883 28.9922 22.7826 28.8673 22.7675C28.7424 22.7523 28.6278 22.8334 28.6114 22.9485Z"
                          fill="#000088"
                        />
                        <path
                          d="M24.9922 18.9234L27.6513 19.063L28.9175 18.923L27.6513 18.7832L24.9922 18.9234Z"
                          fill="#000088"
                        />
                        <path
                          d="M27.5174 22.6182C27.4692 22.7255 27.5245 22.8485 27.6409 22.893C27.7572 22.9374 27.8907 22.8865 27.9389 22.7792C27.9871 22.6719 27.9318 22.5488 27.8154 22.5044C27.699 22.4599 27.5656 22.5109 27.5174 22.6182Z"
                          fill="#000088"
                        />
                        <path
                          d="M25.1406 17.8657L27.6698 18.6356L28.9322 18.8025L27.7484 18.3649L25.1406 17.8657Z"
                          fill="#000088"
                        />
                        <path
                          d="M26.5393 22.0368C26.4626 22.1289 26.4815 22.2611 26.5814 22.3318C26.6814 22.4025 26.8245 22.3851 26.9012 22.293C26.9779 22.2009 26.959 22.0688 26.859 21.9981C26.7591 21.9274 26.6159 21.9447 26.5393 22.0368Z"
                          fill="#000088"
                        />
                        <path
                          d="M25.5781 16.8809L27.805 18.2283L28.9776 18.6908L27.9569 17.9857L25.5781 16.8809Z"
                          fill="#000088"
                        />
                        <path
                          d="M25.7611 21.2427C25.6611 21.3134 25.6423 21.4454 25.719 21.5376C25.7957 21.6298 25.9389 21.6472 26.0388 21.5765C26.1387 21.5058 26.1575 21.3737 26.0808 21.2816C26.0041 21.1894 25.861 21.172 25.7611 21.2427Z"
                          fill="#000088"
                        />
                        <path
                          d="M26.2812 16.0327L28.0542 17.8657L29.057 18.5923L28.269 17.6676L26.2812 16.0327Z"
                          fill="#000088"
                        />
                        <path
                          d="M25.2424 20.2924C25.1261 20.3369 25.0708 20.4599 25.119 20.5672C25.1672 20.6745 25.3007 20.7255 25.417 20.6811C25.5334 20.6366 25.5886 20.5136 25.5404 20.4063C25.4922 20.2989 25.3588 20.248 25.2424 20.2924Z"
                          fill="#000088"
                        />
                        <path
                          d="M27.2109 15.3853L28.4089 17.5789L29.1736 18.5201L28.6721 17.4389L27.2109 15.3853Z"
                          fill="#000088"
                        />
                        <path
                          d="M25.003 19.2469C24.8781 19.2621 24.7902 19.3677 24.8067 19.4829C24.8231 19.598 24.9377 19.6791 25.0626 19.664C25.1875 19.6488 25.2754 19.5432 25.2589 19.428C25.2425 19.3128 25.1279 19.2318 25.003 19.2469Z"
                          fill="#000088"
                        />
                        <path
                          d="M28.2812 14.9761L28.8228 17.3809L29.2973 18.4726L29.1164 17.3084L28.2812 14.9761Z"
                          fill="#000088"
                        />
                        <path
                          d="M25.0626 18.1737C24.9377 18.1585 24.8231 18.2396 24.8067 18.3547C24.7903 18.4699 24.8782 18.5755 25.0031 18.5907C25.128 18.6059 25.2426 18.5248 25.259 18.4097C25.2754 18.2945 25.1875 18.1889 25.0626 18.1737Z"
                          fill="#000088"
                        />
                        <path
                          d="M29.4248 14.8306L29.2734 17.2827L29.4253 18.4504L29.5768 17.2827L29.4248 14.8306Z"
                          fill="#000088"
                        />
                        <path
                          d="M25.417 17.1645C25.3006 17.1201 25.1672 17.171 25.119 17.2783C25.0708 17.3856 25.1261 17.5086 25.2425 17.5531C25.3589 17.5975 25.4923 17.5466 25.5405 17.4393C25.5886 17.332 25.5334 17.209 25.417 17.1645Z"
                          fill="#000088"
                        />
                        <path
                          d="M30.5706 14.9771L29.7356 17.3092L29.5547 18.4734L30.0292 17.3819L30.5706 14.9771Z"
                          fill="#000088"
                        />
                        <path
                          d="M26.0466 16.2676C25.9467 16.1969 25.8035 16.2142 25.7268 16.3064C25.6502 16.3985 25.669 16.5305 25.769 16.6012C25.8689 16.672 26.0121 16.6546 26.0888 16.5625C26.1654 16.4703 26.1466 16.3383 26.0466 16.2676Z"
                          fill="#000088"
                        />
                        <path
                          d="M31.6423 15.3848L30.1812 17.4383L29.6797 18.5196L30.4444 17.5784L31.6423 15.3848Z"
                          fill="#000088"
                        />
                        <path
                          d="M26.909 15.5516C26.8323 15.4594 26.6891 15.442 26.5892 15.5127C26.4893 15.5834 26.4705 15.7155 26.5472 15.8076C26.6239 15.8998 26.767 15.9172 26.8669 15.8465C26.9669 15.7758 26.9857 15.6438 26.909 15.5516Z"
                          fill="#000088"
                        />
                        <path
                          d="M32.5569 16.0337L30.5692 17.6684L29.7812 18.5932L30.7842 17.8666L32.5569 16.0337Z"
                          fill="#000088"
                        />
                        <path
                          d="M27.9388 15.0674C27.8906 14.96 27.7572 14.9091 27.6408 14.9536C27.5245 14.998 27.4692 15.1211 27.5174 15.2284C27.5656 15.3357 27.699 15.3866 27.8154 15.3421C27.9317 15.2977 27.987 15.1747 27.9388 15.0674Z"
                          fill="#000088"
                        />
                        <path
                          d="M33.2589 16.8804L30.8801 17.9851L29.8594 18.6903L31.032 18.2278L33.2589 16.8804Z"
                          fill="#000088"
                        />
                        <path
                          d="M29.0715 14.8431C29.0551 14.7279 28.9405 14.6469 28.8156 14.662C28.6907 14.6772 28.6028 14.7829 28.6192 14.898C28.6357 15.0132 28.7502 15.0942 28.8751 15.0791C29 15.0639 29.0879 14.9582 29.0715 14.8431Z"
                          fill="#000088"
                        />
                        <path
                          d="M33.7058 17.8643L31.0979 18.3637L29.9141 18.8012L31.1766 18.6343L33.7058 17.8643Z"
                          fill="#000088"
                        />
                        <path
                          d="M30.2355 14.895C30.252 14.7799 30.164 14.6742 30.0391 14.6591C29.9142 14.6439 29.7996 14.725 29.7832 14.8401C29.7667 14.9553 29.8547 15.0609 29.9796 15.0761C30.1045 15.0912 30.2191 15.0102 30.2355 14.895Z"
                          fill="#000088"
                        />
                        <path
                          d="M33.855 18.9204L31.1959 18.7808L29.9297 18.9209L31.1959 19.0606L33.855 18.9204Z"
                          fill="#000088"
                        />
                        <path
                          d="M31.3294 15.2284C31.3776 15.121 31.3224 14.998 31.206 14.9536C31.0896 14.9091 30.9562 14.9601 30.908 15.0674C30.8598 15.1747 30.9151 15.2977 31.0314 15.3422C31.1478 15.3866 31.2812 15.3357 31.3294 15.2284Z"
                          fill="#000088"
                        />
                        <path
                          d="M33.7057 19.9803L31.1765 19.2103L29.9141 19.0435L31.0978 19.4811L33.7057 19.9803Z"
                          fill="#000088"
                        />
                        <path
                          d="M32.3074 15.812C32.3841 15.7199 32.3653 15.5878 32.2653 15.5171C32.1654 15.4464 32.0222 15.4638 31.9455 15.556C31.8689 15.6481 31.8877 15.7802 31.9876 15.8509C32.0875 15.9216 32.2307 15.9042 32.3074 15.812Z"
                          fill="#000088"
                        />
                        <path
                          d="M33.2588 20.9676L31.032 19.6202L29.8594 19.1577L30.88 19.8629L33.2588 20.9676Z"
                          fill="#000088"
                        />
                        <path
                          d="M33.0779 16.6042C33.1778 16.5335 33.1967 16.4014 33.12 16.3093C33.0433 16.2171 32.9002 16.1998 32.8002 16.2705C32.7003 16.3412 32.6814 16.4732 32.7581 16.5654C32.8348 16.6575 32.9779 16.6749 33.0779 16.6042Z"
                          fill="#000088"
                        />
                        <path
                          d="M32.5569 21.814L30.7842 19.981L29.7812 19.2544L30.5692 20.1792L32.5569 21.814Z"
                          fill="#000088"
                        />
                        <path
                          d="M33.6045 17.5561C33.7209 17.5116 33.7762 17.3886 33.728 17.2813C33.6798 17.1739 33.5463 17.123 33.43 17.1675C33.3136 17.2119 33.2583 17.3349 33.3065 17.4422C33.3547 17.5496 33.4881 17.6005 33.6045 17.5561Z"
                          fill="#000088"
                        />
                        <path
                          d="M31.6345 22.4634L30.4366 20.2697L29.6719 19.3286L30.1734 20.4099L31.6345 22.4634Z"
                          fill="#000088"
                        />
                        <path
                          d="M33.8438 18.6005C33.9687 18.5853 34.0566 18.4797 34.0402 18.3645C34.0237 18.2494 33.9092 18.1683 33.7843 18.1835C33.6594 18.1986 33.5715 18.3043 33.5879 18.4195C33.6044 18.5346 33.7189 18.6157 33.8438 18.6005Z"
                          fill="#000088"
                        />
                        <path
                          d="M30.5707 22.8714L30.0293 20.4666L29.5547 19.375L29.7357 20.5391L30.5707 22.8714Z"
                          fill="#000088"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1585_8014">
                          <rect width="60" height="38" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>{" "}
                    with <i className="fa-solid fa-heart"></i>{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Footer;
