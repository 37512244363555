import React, { useEffect } from "react";

import { Helmet } from "react-helmet";
import HomeNav from "../components/HomeNav";
import playstore from "../assets/images/gblack.png";
import applestore from "../assets/images/ablack.png";
// import manimg from "../assets/images/partner/man.png";
import { Link } from "react-router-dom";
const Application = ({ updateMetaTags }) => {
  useEffect(() => {
    updateMetaTags(
      "Truxcargo the App  | TRUXCARGO",

      "Discover endless opportunities to advance your career and make a meaningful impact with TRUXCARGO's dynamic and forward-thinking team.",
      "Stay Current on TRUXCARGO Developments and Job Openings"
    );
  }, [updateMetaTags]);
  const carrers = {
    background: `url("/image/mobile/app3.png")`,
    width: "100%",
    height: "100vh",
    // backgroundSize: "100% 100%",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    padding: "7% 0%",
    // display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  };

  return (
    <>
      <Helmet>
        <title>Download the App </title>
      </Helmet>
      <HomeNav position="absolute" topValue="inherit" />

      <section className="contact_section application_scan" style={carrers}>
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-md-6 col-11">
              <div className="text-center">
                <h2
                  style={{
                    backgroundImage:
                      "linear-gradient(144deg, #af40ff, #5b42f3 50%, #00ddeb)",
                    color: " transparent",
                    webkitBackgroundClip: "text",
                    fontWeight: "bold",
                  }}
                >
                  Scan to Download the Truxcargo App{" "}
                </h2>
                <div className="my-2">
                  <img
                    src="/image/mobile/TRUXCARGO.png"
                    alt=""
                    srcSet=""
                    style={{
                      border: "2px solid #000",
                      padding: "1px ",
                      width: "120px",
                      height: "120px",
                    }}
                  />
                </div>
                <h5>Still using website for orders? Download our app</h5>
              </div>

              <div className="row justify-content-center">
                <div className="col-md-6 col-10">
                  <div className="d-flex gap-2 mt-2">
                    <Link
                      to="https://play.google.com/store/apps/details?id=com.Truxcargo&hl=en"
                      target="_blank"
                    >
                      {" "}
                      <img src={playstore} alt="" srcSet="" className="w-100" />
                    </Link>
                    <Link
                      to="https://apps.apple.com/in/app/truxcargo-courier-track/id6505090308"
                      target="_blank"
                    >
                      {" "}
                      <img
                        src={applestore}
                        alt=""
                        srcSet=""
                        className="w-100"
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div style={{ marginTop: "-28px" }}>
          <img src="/image/mobile/app1.png" alt="" srcSet="" className="w-100" />
        </div> */}
      </section>
    </>
  );
};

export default Application;
