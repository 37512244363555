import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";

import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const TrackShipment = () => {
  const [trackingNo, setTrackingNo] = useState("");
  const [pincode_No, setpincode_No] = useState("");
  const [error, setError] = useState(null); // State for tracking errors
  const [errorPincode, setErrorPincode] = useState(null); // State for tracking errors
  const [selectedOption, setSelectedOption] = useState("1");

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const navigate = useNavigate();

  const handleTrackClick = () => {
    // console.log("outside",trackingNo )

    if (trackingNo.trim() === "") {
      // console.log("i am if",trackingNo )
      setError("Please enter valid tracking number");
    } else if (trackingNo.length < 6) {
      // console.log("i am elseif first",trackingNo.length )

      setError("Please enter least six digits number");
    } else if (
      trackingNo.length > 18 &&
      /^[0-9a-zA-Z-\s,]{18,}$/.test(trackingNo)
    ) {
      // console.log("i am elseif second",trackingNo.length )

      setError(null);
      navigate(`/multiple_tracking_details/${trackingNo}`);
    } else if (
      trackingNo.length > 5 &&
      /^[0-9a-zA-Z-\s,]{5,}$/.test(trackingNo)
    ) {
      // console.log("i am elseif third",trackingNo.length )

      setError(null);
      navigate(`/tracking_details/${trackingNo}`);
    } else {
      // console.log("Invalid Tracking number",trackingNo )

      setError("Invalid Tracking number");
    }

    setTrackingNo("");
  };

  const handlePincodeCheck = () => {
    if (pincode_No.trim() === "") {
      setErrorPincode("Please enter a valid PIN Code");
    } else if (pincode_No.length < 6) {
      setErrorPincode("Please enter at least six digits number");
    } else if (
      pincode_No.length === 6 &&
      /^[0-9a-zA-Z-]{6}$/.test(pincode_No)
    ) {
      setErrorPincode(null);
      navigate(`/pincode_details/${pincode_No}`);
    } else {
      setErrorPincode("Invalid Pincode");
    }

    setpincode_No("");
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent the default form submission behavior (refresh)
      handleTrackClick(); // Call the same function as the track button click
      handlePincodeCheck();
    }
  };

  // const handleInputChange = (e) => {
  //   setTrackingNo(e.target.value); // Update trackingNo state
  //   setpincode_No(e.target.value);
  // };
  const handleInputChange = (e) => {
    setTrackingNo(e.target.value.trim()); // Update trackingNo state
  };
  const handleInputChangePincode = (e) => {
    setpincode_No(e.target.value.trim());
  };

  return (
    <>
      <section className="my-4">
        <div className="track-shipments">
          {/* <h5 className="mb-3" style={{color: "rgba(0, 0, 0, 0.50)"}}>Track and Trace by:</h5> */}
          <Form className="d-flex">
            <FormControl sx={{ m: 1, minWidth: 100 }} size="small">
              <Select
                value={selectedOption}
                onChange={handleChange}
                displayEmpty
                autoWidth
                inputProps={{ "aria-label": "Without label" }}
                sx={{
                  ".MuiSelect-select": {
                    padding: "3px 6px",
                    border: "none",
                    color: "#7939cc",
                  },
                  ".MuiSelect-icon": {
                    fontSize: "1rem",
                    color: "#ffffff",
                    background:
                      "linear-gradient(180deg, #7939CC 0%, #4656A3 100%)",
                    filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
                    borderRadius: "2px",
                  },
                  ".MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                }}
              >
                <MenuItem
                  value="1"
                  sx={{
                    "&.Mui-selected": {
                      border: "1px solid #FFF",
                      background:
                        "linear-gradient(97deg, rgba(121, 57, 204, 0.90) 0%, rgba(70, 86, 163, 0.90) 100%)",
                      color: "#fff",
                    },
                  }}
                >
                  Tracking
                </MenuItem>
                <MenuItem
                  value="2"
                  sx={{
                    "&.Mui-selected": {
                      border: "1px solid #FFF",
                      background:
                        "linear-gradient(97deg, rgba(121, 57, 204, 0.90) 0%, rgba(70, 86, 163, 0.90) 100%)",
                      color: "#fff",
                    },
                  }}
                >
                  Pincode
                </MenuItem>
              </Select>
            </FormControl>
   

            <div className="border-horizontal"></div>

            {selectedOption === "1" && (
              <>
                <div className="w-100">
                  <div className="tracking_shipment">
                    <input
                      type="text"
                      placeholder="Enter Tracking No."
                      value={trackingNo}
                      className="form-control w-100 "
                      onChange={handleInputChange}
                      // onChange={(e) => setTrackingNo(e.target.value)}
                      onKeyPress={handleKeyPress} // Call handleKeyPress on key press
                      required
                    />
                    <button
                      type="button"
                      className="button-34"
                      onClick={handleTrackClick}
                    >
                      Track
                    </button>
                  </div>

                  <div>
                    {error && <p className="error-message">{error}</p>}{" "}
                  </div>
                </div>
              </>
            )}
            {selectedOption === "2" && (
              <>
                <div className="w-100">
                  <div className="tracking_shipment">
                    <input
                      type="text"
                      placeholder="Enter PIN Code"
                      value={pincode_No}
                      className="form-control w-100 "
                      onChange={handleInputChangePincode}
                      // onChange={(e) => setTrackingNo(e.target.value)}
                      onKeyPress={handleKeyPress} // Call handleKeyPress on key press
                      required
                    />
                    <button
                      type="button"
                      className="button-34"
                      onClick={handlePincodeCheck}
                    >
                      Check
                    </button>
                  </div>
                  {errorPincode && (
                    <p className="error-message">{errorPincode}</p>
                  )}{" "}
                </div>
              </>
            )}
          </Form>
        </div>
        {selectedOption === "1" ? (
          <small>
            To track multiple LR, enter tracking numbers separated by comma.
          </small>
        ) : null}
      </section>
    </>
  );
};

export default TrackShipment;
