import React, {  useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import Accordion from "react-bootstrap/Accordion";
// import AccordionContext from "react-bootstrap/AccordionContext";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import Card from "react-bootstrap/Card";
import Calculator from "./Calculator";
import HomeNav from "./HomeNav";
import CalculatorLoader from "../Loader/CalculatorLoader";

const ResultPage = () => {
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const rateData = location.state;
  const body = location.state;
// console.log("rate pass api" , rateData)
// console.log("body pass api" , body)
  useEffect(() => {
    const fetchData = async () => {
      try {
        setTimeout(() => {
          // console.log("loading true");
          setLoading(false);
        }, 1000); // Adjust the delay as needed
      } catch (error) {
        console.error("Error fetching API data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  function ContextAwareToggle({ children, eventKey, callback }) {
    // const { activeEventKey } = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(
      eventKey,
      () => callback && callback(eventKey)
    );

    // const isCurrentEventKey = activeEventKey === eventKey;

    return (
      <button
        type="button"
        className="btn Charges_Bifurcation"
        onClick={decoratedOnClick}
      >
        {children}
      </button>
    );
  }

  
  
  return (
    <>
      <HomeNav position="absolute" topValue="inherit" />
      <div className="calculator_result" style={{ marginTop: "7rem" }}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-5 col-md-12 order-md-2 order-2">
            <Calculator body = {body} />
            </div>
            <div className=" col-lg-7 col-md-12 order-md-1 order-1 ">
              <div>
                {loading ? (
                 <CalculatorLoader/>
                ) : (
                  <div>
                     <div className="fixed_height">

                     {rateData?.rateData?.map((data, index) => (

                       <Accordion key={index} defaultActiveKey="1">
                         <Card>
                           <Card.Header>
                             <div className="flex_dynamic_rates">
                               <img
                                 src={data.image}
                                 alt=""
                                 srcSet=""
                                 className="w-100"
                               />
                               <div style={{width: "45%"}}>
                                 <p className="title">
                                   {" "}
                                   {data.title}
                                 </p>

                                 <ContextAwareToggle eventKey="0">
                                   <i className="fa-solid fa-indian-rupee-sign"></i>{" "}
                                   Charges Bifurcation 
                                 </ContextAwareToggle>
                               </div>

                               <p>
                                 <small style={{fontSize: "10px"}}>
                                   {" "}
                                   <i className="fa-solid fa-indian-rupee-sign"></i>
                                 </small>
                                 <b  style={{fontSize: "17px"}}> {data.total}</b>
                               </p>
                             </div>
                           </Card.Header>
                           <Accordion.Collapse eventKey="0">
                             <Card.Body>
                               <ul className="body_rate_cal">
                                 {data.freight &&
                                  data.cod_charge &&
                                  data.fuel &&
                                  data.gst ? (
                                   <>
                                     <li>
                                       Freight Amount:
                                       <span>
                                         <i className="fa-solid fa-indian-rupee-sign"></i>
                                         {data.freight}
                                       </span>
                                     </li>
                                     {data.cod_charge > 0 && (
                                       <li>
                                         COD Charge:
                                         <span>
                                           {" "}
                                           <i className="fa-solid fa-indian-rupee-sign"></i>
                                           {data.cod_charge}
                                         </span>
                                       </li>
                                     )}

                                     <li>
                                       Fuel Surcharge:
                                       <span >
                                         <i className="fa-solid fa-indian-rupee-sign"></i>
                                         {data.fuel}
                                       </span>
                                     </li>
                                     <li>
                                       GST:
                                       <span>
                                         <i className="fa-solid fa-indian-rupee-sign"></i>
                                         {data.gst}
                                       </span>
                                     </li>
                                   </>
                                 ) : (
                                   <>
                                     <li>
                                       Charged Weight:
                                       <span>
                                         {" "}
                                         {data.charged_weight}
                                         {data.unit}
                                       </span>
                                     </li>
                                     <li>
                                       Weight Charge{" "}
                                       <small>
                                         ({data.rate}/{data.unit}):
                                       </small>
                                       <span>
                                         {" "}
                                         <i className="fa-solid fa-indian-rupee-sign"></i>
                                         {data.weight_charge}
                                       </span>
                                     </li>
                                     <li>
                                       Docket Charge:{" "}
                                       <span>
                                         <i className="fa-solid fa-indian-rupee-sign"></i>
                                         {data.docket}
                                       </span>
                                     </li>
                                     <li>
                                       Fuel Surcharge:
                                       <span>
                                         <i className="fa-solid fa-indian-rupee-sign"></i>
                                         {data.fuel}
                                       </span>
                                     </li>
                                     <li>
                                       ROV Charge:
                                       <span>
                                         <i className="fa-solid fa-indian-rupee-sign"></i>
                                         {data.rov}
                                       </span>
                                     </li>

                                     {data.oda > 0 && (
                                       <li>
                                         ODA Charge:
                                         <span>
                                           <i className="fa-solid fa-indian-rupee-sign"></i>
                                           {data.oda}
                                         </span>
                                       </li>
                                     )}
                                     <li>
                                       GST:
                                       <span>
                                         <i className="fa-solid fa-indian-rupee-sign"></i>
                                         {data.gst}
                                       </span>
                                     </li>
                                   </>
                                 )}
                               </ul>
                             </Card.Body>
                           </Accordion.Collapse>
                         </Card>
                       </Accordion>
                     ))}
                   </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResultPage;
